/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./components/basic');
// require('./components/basic');
//var AOS = require('aos');
//AOS.init();

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

// Vue.component('example', require('./components/Example.vue'));
//
// const app = new Vue({
//     el: 'body'
// });

$(document).ready(function () {

    var pathArray = window.location.pathname.split('/');

    if ( (pathArray[1] == "en") || (pathArray[1] == "ro") ) {
        $("#homepage").attr("href", $("#homepage").attr("href") + "/" + pathArray[1]);
    }
});
