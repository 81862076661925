// require('../modules/jquery.magnific-popup.min');
//require('../modules/bee3D.min.js');
require('../modules/jquery.magnific-popup.min.js');
require('../modules/select2.min.js');

$(document).ready(function(){

	//header calculator
	$('.search-activator').on('click',function(){
		$('.header-calculator').addClass('active');
	});

	$('.close-header-calculator').on('click',function(){
		$('.header-calculator').removeClass('active');
	});

	//nav dropdown
	$('nav li').click(function(){
		$(this).children('div').slideToggle();
		$(this).toggleClass('active');

		$('nav .drop-d').not($(this).children('div')).slideUp();
		$('nav li').not($(this)).removeClass();
	})

	//select2 plugin
	$('.ab-select').select2();

    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });

	$('.back-to-top').on('click',function(){
		$('html,body').animate({
			scrollTop:0
		},1300);
	});

	//answer reveald from grid cars
	$(".grid-items .question").click(function(){
		$(this).parent().siblings('.answer').slideDown(0);
		$(".grid-items .answer").not($(this).parent().siblings('.answer')).slideUp(0);
	});
	$(".grid-items .close-answer").click(function(){
		$(".grid-items .answer").slideUp(0);
	});

	//header hamburger
	$(".hamburger").click(function(){
	    $(this).toggleClass("open");
	    $('nav').slideToggle();
	});

	$('.magnific').magnificPopup({
		type: 'image',
		gallery:{
			enabled:true
		}
    });
});
